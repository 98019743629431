import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

const AltEnergyTemplate = ({ data }) => {
	const { sanityAltEnergyPg: pg } = data

	return (
		<DefaultLayout {...pg.seo}>
			{pg.blocks?.map((block) => (
				<Block {...block} key={block?._key} />
			))}
		</DefaultLayout>
	)
}

export default AltEnergyTemplate

export const query = graphql`
	query AltEnergyTemplate($id: String) {
		sanityAltEnergyPg(id: { eq: $id }) {
			blocks {
				... on SanityBlockGroup {
					_key
					_type
					style
					blocks {
						...statList
					}
				}
				...altEnergyContent
				...heroBlock
				...heroPostcard
				...heroTextStack
				...hvacContent
				...featuresWithIcons
				...imageAndTextSections
				...logoListScroll
				...partners
				...projectList
				...safetyStats
				...servicesList
				...textWithBg
			}
			seo {
				title
				description
			}
		}
	}
`
